import type {
  Point,
  ProfileListItemPublic,
  ProfileListItemSitePublic,
  ProfilePublic,
  Radius,
  StateDetails,
} from "@ddr/models";
import { Button, Dialog, ReactTextLink } from "@ddr/ui";
import { type ProfileMarker, MarkerMap } from "@ddr/ui/src/map";
import { useCallback, useEffect, useState } from "react";
import ProfileListItem from "../components/profile-list-item";
import ProfileDetailView from "../components/profile-detail-view";
import { API_URL, BG_DARK, NEXT_URL, shop } from "../config";
import LocationSearch from "@ddr/ui/src/location-search";
import ContactDialog from "../components/contact-dialog";
import { getConnections, getConnectionsMap } from "../data/connections";
import { Crosshair } from "lucide-react";
import { ErrorBox } from "@ddr/ui";
import { getCurrentLocationSafe } from "../utils/geo";
import { fetchJson } from "@kennethkeim/core";
import { handleError } from "../utils/error";
import { ErrorBoundary } from "../components/error-boundary";
import { calculateDistance, getStateDetails } from "@ddr/utils";

// Only one site (at a time) is ever relevant to this page
type ProfileFlat = ProfileListItemPublic & {
  site: ProfileListItemSitePublic;
  distance?: number;
};

const defaultZoom = 9.4;

type SortMode = "distance" | "rating";

const MAP_RADIUS_DEFAULT = 75;
const radiusOptions = [
  { value: "all", label: "Show All" },
  { value: "75", label: "75 Mi" },
  { value: "150", label: "150 Mi" },
  { value: "500", label: "500 Mi" },
];

const currLocationLabel = "your approximate location";

export default function PilotMap() {
  const [selectedState, setSelectedState] = useState<StateDetails | null>(null);
  const [ipLocation, setIpLocation] = useState<Point>();
  const [inputLocation, setInputLocation] = useState<Point>();
  const [radius, setRadius] = useState<Radius>(MAP_RADIUS_DEFAULT);
  const [profiles, setProfiles] = useState<ProfileFlat[]>([]);
  const [profileMarkers, setProfileMarkers] = useState<ProfileMarker[]>([]);
  const [detailViewOpen, setDetailViewOpen] = useState(false);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState<ProfileFlat>();
  const [sortMode, setSortMode] = useState<SortMode>("distance");
  // Useful if we want to set zoom from parent component
  // const [zoom, setZoom] = useState(defaultZoom);
  const center = inputLocation ?? ipLocation;
  // In show all mode, sort by current location, else give precedence to input location (if any)
  const sortCenter = radius === "all" ? ipLocation : center;
  const [userHasClicked, setUserHasClicked] = useState(false);
  // const [error, setError] = useState<string | null>(null);

  const { activeConnections } = getConnections();
  const connectionsMap = getConnectionsMap(activeConnections);

  // Note: this is nullable so we can infer loading state
  const [unsortedProfiles, setUnsortedProfiles] = useState<
    ProfileFlat[] | null
  >(null);

  useEffect(() => {
    const pathParts = window.location.pathname.split("/");
    const slug = pathParts[pathParts.length - 1];
    const stateFromUrl = slug
      ?.replace("drone-deer-recovery-in-", "")
      .toLowerCase();
    const stateDetails = getStateDetails(stateFromUrl ?? "");
    if (stateDetails) {
      setSelectedState(stateDetails);
      setSortMode("rating");
    }
  }, []);

  useEffect(() => {
    setUnsortedProfiles(null);
    if (!center && !selectedState) return;

    const url = selectedState
      ? `${API_URL}/profiles?state=${selectedState.code}`
      : `${API_URL}/profiles?lat=${center?.lat}&lng=${center?.lng}&radius=${radius}`;

    fetchJson<ProfilePublic[]>(url)
      .then((res) => {
        try {
          const flat = res.data.map<ProfileFlat>((p) => ({
            ...p,
            site: p.sites[0],
          }));
          setUnsortedProfiles(flat);

          // TLDR: This should not run when sorting updates
          // Map center updates when center variable changes, and then map re-centers if result set changes
          // If this logic runs after each profile sort:
          // When changing from some to no results (e.g Ohio to Cali), the map re-centers on the same (but re-sorted) list
          // Causing center to stay in Ohio instead of Cali
          const markers = res.data.map<ProfileMarker>((p) => ({
            // Pilots without public location are filtered out in the backend so we can assume it's always there
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            position: p.publicLocation!,
            id: p.account_handle,
          }));
          setProfileMarkers(markers);
        } catch (renderError) {
          const forReport = new Error("Failed to render pilot list.", {
            cause: renderError,
          });
          handleError(
            forReport,
            "Failed to show list of pilots",
            "Please try again",
          );
        }
      })
      .catch((err) => {
        handleError(err, "Failed to load pilot profiles", "Please try again.");
      });
  }, [center, radius, selectedState]);

  const sortProfilesByDistance = useCallback(
    (profiles: ProfileFlat[], centerPoint: Point) => {
      const profilesWithDistance = profiles.map((p) => ({
        ...p,
        distance: p.publicLocation
          ? calculateDistance(centerPoint, p.publicLocation)
          : Infinity,
      }));

      return profilesWithDistance.sort((a, b) => a.distance - b.distance);
    },
    [],
  );

  const sortProfilesByRating = useCallback((profiles: ProfileFlat[]) => {
    return profiles.sort((a, b) => {
      // Sort by avg rating, number of ratings, number of searches
      if (a.rating === b.rating) {
        if (a.numberOfRatings === b.numberOfRatings) {
          if (a.numberOfSearches === b.numberOfSearches) {
            return Math.random() - Math.random();
          }
          return b.numberOfSearches - a.numberOfSearches;
        }
        return b.numberOfRatings - a.numberOfRatings;
      }
      return b.rating - a.rating;
    });
  }, []);

  useEffect(() => {
    if (!unsortedProfiles?.length) {
      setProfiles([]);
    } else if (sortMode === "distance" && sortCenter) {
      setProfiles(sortProfilesByDistance(unsortedProfiles, sortCenter));
    } else if (sortMode === "rating") {
      setProfiles(sortProfilesByRating(unsortedProfiles));
    } else {
      setProfiles(unsortedProfiles);
    }
  }, [
    sortCenter,
    unsortedProfiles,
    sortProfilesByDistance,
    sortProfilesByRating,
    sortMode,
  ]);

  useEffect(() => {
    void getCurrentLocationSafe().then((l) => {
      if (!l) return;
      setIpLocation({
        lat: Number(l.latitude),
        lng: Number(l.longitude),
        name: currLocationLabel,
      });
    });
  }, []);

  const handleCrosshairClick = useCallback(async () => {
    setUserHasClicked(true);
    const loc = await getCurrentLocationSafe();
    if (!loc) return;
    setIpLocation({
      lat: Number(loc.latitude),
      lng: Number(loc.longitude),
      name: currLocationLabel,
    });
    setInputLocation(undefined);
    setRadius(MAP_RADIUS_DEFAULT);
  }, []);

  const handleMarkerClick = useCallback(
    (profileId: string) => {
      const profile = profiles.find((p) => p.account_handle === profileId);
      setSelectedProfile(profile);
      setDetailViewOpen(true);
    },
    [profiles],
  );

  const handleRadius = useCallback((radiusInput: string) => {
    const radius: Radius = radiusInput === "all" ? "all" : Number(radiusInput);
    setUserHasClicked(true);
    setRadius(radius);
    // Reasons for setting location:
    // * so the map re-centers right away before results load
    // * so the prior location search is cleared
    // Now that I have a loading state we don't need this to indicate ui is updating based on filters
    // When user clicks show all and then other radius button, the list was sorted by distance from center of US
    // and this is not expected. It's better to sort by the last location entered by the user OR clear input location and the text in the search box
    // if (radius === "all") {
    //   // Center of the Us
    //   setInputLocation({
    //     lat: 39.8,
    //     lng: -98.55,
    //     name: "center of the United States",
    //   });
    // }
  }, []);

  const handleLocationSearch = useCallback((location: Point) => {
    // Popup won't close if we use a fn directly instead of useCallback
    setUserHasClicked(true);
    setInputLocation(location);
    setRadius(MAP_RADIUS_DEFAULT);
  }, []);

  const profilesList = profiles.map((p) => {
    const site = p.sites.find((s) => s.site === "DEER");
    if (!site) return null;
    return (
      <ProfileListItem
        key={p.id}
        profile={p}
        existingConnection={connectionsMap.has(p.account_handle)}
        onViewMoreClick={() => {
          setSelectedProfile(p);
          setDetailViewOpen(true);
        }}
        onContactPilotClick={() => {
          setSelectedProfile(p);
          setContactModalOpen(true);
        }}
      />
    );
  });

  const mapErrorFallback = (
    <ErrorBox
      title="Sorry, the map is not working at the moment."
      description="You may be still able to find pilots using the list. Our team has been informed of the issue and will fix it soon."
    />
  );

  return (
    <div
      id="pilot-map"
      className={`${BG_DARK} flex min-h-[400px] justify-center p-2 md:px-20 md:py-10`}
    >
      <div className="bg-secondary flex max-w-[1600px] basis-[1600px] flex-wrap-reverse gap-1 overflow-hidden rounded-lg">
        <Dialog open={contactModalOpen} onOpenChange={setContactModalOpen}>
          {selectedProfile ? <ContactDialog profile={selectedProfile} /> : null}
        </Dialog>

        <div className="h-[600px] grow basis-[400px] overflow-y-scroll p-3 md:h-[800px] xl:h-[900px]">
          {detailViewOpen && selectedProfile ? (
            <ProfileDetailView
              profileId={selectedProfile.displayName}
              existingConnection={connectionsMap.has(
                selectedProfile.account_handle,
              )}
              onClose={() => {
                setDetailViewOpen(false);
                setSelectedProfile(undefined);
              }}
              onContactPilotClick={() => {
                setContactModalOpen(true);
              }}
            />
          ) : (
            <>
              {selectedState ? (
                <div className="flex justify-between gap-3">
                  <p className="text-lg font-bold">
                    Showing results for {selectedState.display}
                  </p>
                </div>
              ) : (
                <nav className="mb-2">
                  <div className="mb-2.5 flex items-center justify-between gap-3">
                    <LocationSearch
                      onSelect={handleLocationSearch}
                      className="basis-full"
                      onError={handleError}
                    />

                    <Button
                      variant="ghost"
                      className="p-0"
                      onClick={handleCrosshairClick}
                    >
                      <Crosshair />
                    </Button>
                  </div>

                  <div className="flex flex-wrap items-center gap-3">
                    {radiusOptions.map((option) => (
                      <Button
                        key={option.value}
                        onClick={() => handleRadius(option.value)}
                        size="sm"
                        // double equals is intentional (number == string)
                        variant={radius == option.value ? "default" : "outline"}
                      >
                        {option.label}
                      </Button>
                    ))}
                  </div>
                </nav>
              )}

              <p className="text-muted-foreground mb-4 text-sm">
                {sortMode === "distance" && sortCenter?.name
                  ? `Sorted by distance from ${sortCenter.name}`
                  : sortMode === "rating"
                    ? `Sorted by average rating`
                    : ""}
              </p>

              {!unsortedProfiles ? (
                // Using unsortedProfiles state to determine if api call finished
                <p className="text-muted-foreground text-center text-xl">
                  Loading...
                </p>
              ) : unsortedProfiles.length ? (
                // Using unsortedProfiles so there's no gap between when api finished and useEffect rendered sorted list
                // If there's a gap, the not found message would show for a split second
                <ul>{profilesList}</ul>
              ) : (
                <div className="text-muted-foreground mt-10 text-center">
                  <p className="mb-2 text-xl font-bold">No pilots found</p>
                  <h2 className="mb-2">
                    Not finding pilots in your area? If you are already a drone
                    pilot and want to sign up with Drone Deer Recovery, please
                    sign up{" "}
                    <ReactTextLink
                      text="here"
                      href={shop.LINKS.SUB_COLLECTION}
                      external
                    />
                    .
                  </h2>
                  <p>
                    If you need help, contact us{" "}
                    <ReactTextLink
                      text="here"
                      href={shop.LINKS.CONTACT}
                      external
                    />
                    .
                  </p>
                </div>
              )}
            </>
          )}
        </div>

        <div className="h-[300px] grow basis-[800px] md:h-[500px] lg:h-[800px] xl:h-[900px]">
          <ErrorBoundary fallback={mapErrorFallback}>
            <MarkerMap
              center={center}
              userHasClicked={userHasClicked}
              containerStyle={{
                width: "100%",
                height: "100%",
              }}
              zoom={defaultZoom}
              onMarkerClick={handleMarkerClick}
              markers={profileMarkers}
              iconUrl={`${NEXT_URL}/map-icon.svg`}
              bounds={selectedState?.bounds}
            ></MarkerMap>
          </ErrorBoundary>
        </div>
      </div>
    </div>
  );
}
