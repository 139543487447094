import type { ApproxLocation } from "@ddr/models";
import { handleError } from "./error";
import { fetchJson } from "@kennethkeim/core";

export const getCurrentLocationSafe =
  async (): Promise<ApproxLocation | null> => {
    try {
      const res = await fetchJson<ApproxLocation>(
        "https://geo.keimdigital.workers.dev",
      );
      return res.data;
    } catch (err) {
      handleError(
        err,
        "Failed to get your location",
        "Please enter it manually.",
      );
      return null;
    }
  };
