"use client";
import { Component, type ErrorInfo, type ReactNode } from "react";
import * as Sentry from "@sentry/react";
import { defaultSentryTags } from "../utils/error";
import { ENV_NAME } from "../config";

interface Props {
  children: ReactNode;
  fallback: ReactNode;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (ENV_NAME === "local") {
      console.error(error);
    } else {
      Sentry.captureException(error, {
        tags: { ...defaultSentryTags, area: "error-boundary" },
        contexts: { errorBoundary: { errorInfo } },
      });
    }
  }

  public render() {
    if (this.state.hasError) {
      return this.props.fallback;
    }

    return this.props.children;
  }
}
