import type { HandleErrFn } from "@ddr/models";
import { toast } from "@ddr/ui";
import * as Sentry from "@sentry/react";
import { ENV_NAME } from "../config";
import { CustomError, shouldReport } from "@kennethkeim/core";

export const defaultSentryTags = { appName: "widgets" };

/** Report error and show toast if message is defined */
export const handleError: HandleErrFn = (
  err: unknown,
  message?: string,
  description?: string,
) => {
  if (err instanceof CustomError && err.details?.msg) {
    toast({
      title: err.details.msg,
      description: err.details.desc,
      variant: "destructive",
    });
  } else if (message) {
    toast({ title: message, description, variant: "destructive" });
  }

  if (ENV_NAME === "local") {
    console.error(err);
  } else if (shouldReport(err)) {
    Sentry.captureException(err, {
      tags: {
        ...defaultSentryTags,
        area: "handle-error-fn",
        // logRocketSess: (window as { logRocketSess?: string }).logRocketSess,
      },
    });
  }
};

/** Report message (has required tags needed in order to not be filtered out in instrument.ts) */
export const captureMsg = (
  message: string,
  tags: Record<string, string> = {},
) => {
  Sentry.captureMessage(message, { tags: { ...tags, ...defaultSentryTags } });
};
