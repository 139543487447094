import * as Sentry from "@sentry/react";
// import LogRocket from "logrocket";

// LogRocket.init("rvktoe/ddr", {});

Sentry.init({
  dsn: "https://4474296f6457476c9e66e375d072f531@o423426.ingest.us.sentry.io/4508350045356032",

  integrations: [
    // Default integrations
    Sentry.globalHandlersIntegration({
      // Don't report errors from errors outside of my react widget
      onerror: false,
      onunhandledrejection: false,
    }),

    // Attempt to disable fetch instrumentation
    // (Could be Sentry fetch instrumentation is interfering with Shopify fetch instrumentation)
    // Suspect integrations/features: Tracing, Breadcrumbs, HttpContext
    Sentry.breadcrumbsIntegration({
      fetch: false,
    }),

    // More integrations
    Sentry.replayIntegration({
      networkDetailAllowUrls: [
        "https://app.dronedeerrecovery.com/api",
        "https://geo.keimdigital.workers.dev",
      ],
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],

  // Define how likely Replay events are sampled when an error occurs.
  replaysOnErrorSampleRate: 1,

  tracesSampleRate: 0,

  // TODO: use react 19 hooks shown here to report all errors (within react root) uncaught by error boundary
  // Should be minimial errors since I can use useState hack to catch everything with error boundary
  // https://docs.sentry.io/platforms/javascript/guides/react/
  // * report unhandled inside my widget
  // * ignore global unhandled

  beforeSend: (event, _hint) => {
    // See notes in root level readme about react widgets error handling
    if (event.tags?.appName) return event;
    return null;
  },
});

// LogRocket.getSessionURL((sessionUrl) => {
//   Sentry.setTag("lrsession", sessionUrl);
//   (window as { logRocketSess?: string }).logRocketSess = sessionUrl;
// });
