// Adding this here as a light wrapper around Google Maps Geocoder
// since this package already has the dependencies for it and utils does not

import { AppError } from "@kennethkeim/core";

/**
 * @param searchTerm A string like "222 E Main St, Anytown, PA" or "Anytown PA" or "Anytown" or "17602".
 * @param countryCode Defaults to "US".
 */
export async function geocode(
  searchTerm: string,
  countryCode: string = "US",
): Promise<google.maps.GeocoderResponse> {
  if (!window.google?.maps?.Geocoder) {
    const msg = "Google Maps did not load";
    throw new AppError(msg, { msg, desc: "Please reload or try again later" });
  }

  const geocoder = new window.google.maps.Geocoder();

  const response = await geocoder.geocode({
    address: searchTerm,
    componentRestrictions: { country: countryCode },
  });

  // Response is typed as non-nullable but I have error reports where it's undefined
  if (!response) throw new Error("No response from geocoder");

  return response;
}
