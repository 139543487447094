export type Option = {
  /** Display name */
  name: string;
  value?: string;
};

// (redefine to avoid dependency on mailer pkg in utils lib)
export interface MailRecipientCfg {
  email: string;
  /** Maximum allowed characters are 70.*/
  name?: string;
}

export type MsgTemplate = {
  to: MailRecipientCfg[];
  subject: string;
  html: string;
  summary?: string;
};

export type Sentiment = "positive" | "negative";

export type Drone = Option & {
  image: string;
  description: string;
};

export type FetchStatus = "loading" | "success" | "error";

export const PHONE = {
  regex: /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/,
  message: "Please use format 123-456-7890",
};

export interface ApiResponse {
  message?: string;
  description?: string;
}

/** Error handler function for frontend */
export type HandleErrFn = (
  err: unknown,
  message?: string,
  description?: string,
) => void;

export type ErrorProps = {
  error: Error & { digest?: string };
  reset: () => void;
};
